jQuery.noConflict(true);

var gutter = 30;

var $http = window.location.protocol + "//";
var $root = window.location.host + "/";
var $baseUrl = 'raja/';
var $fullPath = window.location.host + window.location.pathname;
var $path = $root + window.location.pathname.split('/')[1] + '/';
var $fullUrl = window.location.href;

var urlRoot = $http + $path;
var urlFull = $http + $fullPath;

var socialShare = {
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
    twitter: 'https://twitter.com/home?status=',
    googleplus: 'https://plus.google.com/share?url='
};

var controller;

jQuery(document).ready(function ($) {

    controller = new ScrollMagic.Controller();

    Form.init();

    MainMenu.init();

    ScrollMagicStuff.init();


});