var Form = (function ($) {

    var
        mailVars = {
            contact: {
                form: $('#form_contact'),
                name: $('#name_contact'),
                email: $('#email_contact'),
                more: $('#more_contact'),
                submit: $('#submit_contact'),
                honey: $('#honey_contact'),
                info: $('#info_contact')
            },
            shop: {
                form: $('#form_shop'),
                name: $('#name_shop'),
                email: $('#email_shop'),
                more: $('#more_shop'),
                submit: $('#submit_shop'),
                honey: $('#honey_shop'),
                info: $('#info_shop')
            }
        };

    var init = function () {

        $("body").on('click', '[data-form], .fermer_form', function (event) {
            event.preventDefault();  
            
            var formWrapper = $('.form_wrapper'),
                thisForm = $(this).attr('data-form');

            if ($('body').attr('data-mail-opened') === "false") {
                openMail(thisForm, formWrapper);
            } else {
                closeMail();
            }            
        });    
    }

    //Fermer menu ouvert si click à l'exterieur
    $(document).mouseup(function (e) {
        var formContainer = $(".form");

        // If the target of the click isn't the formContainer
        if (($('body').attr('data-mail-opened') === "true") && (!formContainer.is(e.target) && formContainer.has(e.target).length === 0)) {
            closeMail();
        }
    });

    function openMail(thisForm, formWrapper) {

        $('#form_' + thisForm).show();
        $('body').attr('data-mail-opened', 'true');
        manageMail(thisForm, formWrapper);
    }

    function closeMail() {

        $('.form').hide();
        $('body').attr('data-mail-opened', 'false');
    }


    function manageMail(thisForm, formWrapper) {

        console.log("thisForm");
        console.log(thisForm);

        if (thisForm === "shop") {

            var
                itemType = $('#content').attr('data-type'),
                itemTitleSource = $('[data-source=item-title]'),
                itemPixSource = $('[data-source=item-pix]').attr('src');
                itemTitleGet = $('[data-get=item-title]'),
                itemPixGet = $('[data-get=item-pix]');

                //if(itemType === "lunettes"){
                //    itemTitleSource = itemTitleWrapper.children(".type").text() + ' ' + itemTitleWrapper.children(".item_titre").text() + ' ' + itemTitleWrapper.children(".texte_pour").text() + ' ' + itemTitleWrapper.children(".pour").text();
                //}

                itemTitleGet.html(itemTitleSource.html());
                itemPixGet.attr('src', itemPixSource);
        }

        var url = window.location.protocol + "//" + window.location.host + "/templates/mytmpl/mail_" + thisForm + ".php";

        mailVars[thisForm].form.on('input', mailVars[thisForm].form.name + ',' + mailVars[thisForm].form.email + ',' + mailVars[thisForm].form.more, function () {
            info.html('').slideUp();
        });

        mailVars[thisForm].submit.on('click', function (e) {
            e.preventDefault();
            if (validate(thisForm)) {
                $.ajax({
                    type: "POST",
                    url: url,
                    data: mailVars[thisForm].form.serialize(),
                    dataType: "json"
                }).done(function (data) {
                    console.log("data");
                    console.log(data);
                    if (data.success) {
                        mailVars[thisForm].name.val('');
                        mailVars[thisForm].email.val('');
                        mailVars[thisForm].more.val('');
                        mailVars[thisForm].honey.val('');
                        mailVars[thisForm].info.html('Message envoyé !').css('color', 'green').slideDown();
                        setTimeout(function () {
                            closeMail(formWrapper);
                        }, 2000);
                    } else {
                        mailVars[thisForm].info.html('Impossible d\'envoyer le mail, désolé !').css('color', 'red').slideDown();
                    }
                });
            }
        });

    }

    function validate(thisForm) {
        var valid = true;
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!regex.test(mailVars[thisForm].email.val())) {
            mailVars[thisForm].email.css('border-color', 'red');
            valid = false;
        }

        if ($.trim(mailVars[thisForm].name.val()) === "") {
            mailVars[thisForm].name.css('border-color', 'red');
            valid = false;
        }

        if ($.trim(mailVars[thisForm].honey.val()) !== "") {
            valid = false;
        }

        return valid;
    }


    return {
        init: init
    };

})(jQuery);
