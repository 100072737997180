var ScrollMagicStuff = (function ($) {

    var init = function () {

        controller.scrollTo(function (newpos) {
            TweenMax.to(window, 0.5, {
                scrollTo: {
                    y: newpos
                }
            });
        });

        $(document).on("click", "a[data-special='scrollTo']", function (e) {
            var id = $(this).attr("href");
            if ($(id).length > 0) {
                e.preventDefault();
                controller.scrollTo(id);
            }
        });
    }

    return {
        init: init
    };

})(jQuery);
